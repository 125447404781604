import { useState, ChangeEvent, Fragment } from "react";
function App(): JSX.Element {
  const [text, setText] = useState<string>("");
  const [replacedText, setReplacedText] = useState<string>("");
  const [thickness, setThickness] = useState<number>(0);
  const [replacedThickness, setReplacedThickness] = useState<
    number | undefined
  >(undefined);

  const regex: RegExp =
    /IFCCSHAPEPROFILEDEF\(\.AREA\.,\$,#\d+,\d+\.\d+,\d+\.\d+,\d+\.\d+,\d+\.\d+,\$,\$\);/g;

  const handleTextChange = (event: ChangeEvent<HTMLTextAreaElement>): void => {
    setText(event.target.value);
  };

  const handleThicknessChange = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    setThickness(parseFloat(event.target.value));
  };

  const handleReplace = (): void => {
    //Regex to find specific string containing steel frame thickness
    const newText = text.replaceAll(regex, (match: String) => {
      //Split matched string and replace 6th comma separated value with thickness set by user
      const parts: string[] = match.split(",");
      parts[5] = thickness.toFixed(2).toString();
      return parts.join(",");
    });
    setReplacedThickness(thickness);
    setReplacedText(newText);
  };

  return (
    <div
      style={{
        paddingLeft: "2em",
        paddingRight: "2em",
      }}
    >
      <h1>Scottsdale Steel Frame Thickness Correction Tool v0.3</h1>
      <p>
        <strong>
          Step 1. Open IFC file using Notepad application (or similar text
          editor)
        </strong>
      </p>
      <p>
        <strong>
          Step 2. Copy all text content from Notepad and paste it in the
          following text box:
        </strong>
      </p>
      <textarea
        rows={10}
        cols={50}
        value={text}
        onChange={handleTextChange}
        placeholder="Paste IFC file contents here"
      />
      <p>
        <strong>
          Step 3. Enter the desired thickness value in millimeters up to two
          decimal places and click the "replace" button
        </strong>
      </p>
      <input
        type="number"
        min={0}
        value={thickness}
        onChange={handleThicknessChange}
        placeholder="0.00"
      />
      <button onClick={handleReplace}>Replace</button>
      {replacedText && (
        <Fragment>
          <p>
            <strong>
              Step 4. You have set the thickness to:{" "}
              {replacedThickness?.toFixed(2)}mm
              <br />
              <br />
              Please copy the following output and paste in the open IFC file on
              Notepad, replacing all content
            </strong>
          </p>
          <div
            style={{
              backgroundColor: "#4D4D4D",
              color: "#F2F2F2",
              padding: "1%",
              borderRadius: "1em",
            }}
          >
            <pre>
              <code>{replacedText}</code>
            </pre>
          </div>
          <p>
            <strong>
              Step 5. Save the file and continue about your business
            </strong>
          </p>
          <p>
            <strong>Step 6. Give praise to the Digital Products Team</strong>
          </p>
        </Fragment>
      )}

      <p>Wallace Building Systems Pty Ltd 2023</p>
    </div>
  );
}

export default App;
